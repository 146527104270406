<template>
  <div id="info">
    <tmpl_header />

    <div class="container">
      <sidebar act="info" />

      <div class="main">
        <div class="tabs">
          <ul>
            <li
              data-tab="base"
              :class="{ act: tab == 'base' }"
              @click="switchTab"
            >
              基本信息
            </li>
            <li
              data-tab="other"
              :class="{ act: tab == 'other' }"
              @click="switchTab"
            >
              其他信息
            </li>
          </ul>
        </div>

        <div class="form" v-if="tab == 'base'">
          <div class="form-group">
            <label class="">用户名</label>
            <input
              type="text"
              v-model="data.customerUser"
              readonly="readonly"
            />
          </div>

          <div class="form-group">
            <label class="">会员等级</label>
            <div><span class="vip">云方会员</span></div>
          </div>

          <div class="form-group">
            <label class="must">昵称</label>
            <input
              type="text"
              maxlength="10"
              v-model="data.customerNickname"
              placeholder=""
            />
          </div>

          <div class="form-group">
            <label class="">性别</label>
            <div class="radios">
              <label
                ><input
                  type="radio"
                  name="sex"
                  value="0"
                  v-model="data.infoGender"
                /><span>保密</span></label
              >
              <label
                ><input
                  type="radio"
                  name="sex"
                  value="1"
                  v-model="data.infoGender"
                /><span>男</span></label
              >
              <label
                ><input
                  type="radio"
                  name="sex"
                  value="2"
                  v-model="data.infoGender"
                /><span>女</span></label
              >
            </div>
          </div>

          <div class="form-group">
            <label class="must">真实姓名</label>
            <input type="text" v-model="data.infoRealname" placeholder="" />
          </div>

          <div class="form-group">
            <label>身份证号码</label>
            <input type="text" v-model="data.infoCardid" placeholder="" />
          </div>

          <div class="form-group city">
            <label>所在地</label>

            <select
              v-model="data.infoProvince"
              @change="changeCity"
              data-type="province"
            >
              <option value="" disabled hidden>省</option>
              <option
                v-for="item in provinces"
                :key="item.provinceId"
                :value="item.provinceId"
              >
                {{ item.provinceName }}
              </option>
            </select>
            <select
              v-model="data.infoCity"
              @change="changeCity"
              data-type="city"
            >
              <option value="" disabled hidden>市</option>
              <option
                v-for="item in citys"
                :key="item.cityId"
                :value="item.cityId"
              >
                {{ item.cityName }}
              </option>
            </select>
            <select v-model="data.infoCounty">
              <option value="" disabled hidden>区/县</option>
              <option
                v-for="item in districts"
                :key="item.districtId"
                :value="item.districtId"
              >
                {{ item.districtName }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>详细地址</label>
            <input
              type="text"
              v-model="data.infoAddress"
              maxlength="30"
              placeholder=""
            />
          </div>

          <button class="save" @click="save">保存</button>
        </div>

        <div class="form" v-if="tab == 'other'">
          <div class="form-group birthday">
            <label>生日</label>
            <select v-model="data.infoBirthday.year">
              <option value="">请选择</option>
              <option v-for="i in 80" :key="i">{{ year - i + 1 }}</option>
            </select>
            <select v-model="data.infoBirthday.month" @change="changeMonth">
              <option value="">请选择</option>
              <option v-for="i in 12" :key="i">
                {{ 10 > i ? `0${i}` : i }}
              </option>
            </select>
            <select v-model="data.infoBirthday.day">
              <option value="">请选择</option>
              <option v-for="i in days" :key="i">
                {{ 10 > i ? `0${i}` : i }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>月收入</label>

            <select
              v-model="data.infoSalary"
              style="width: 120px; padding: 0 8px"
            >
              <option value="0">无收入</option>
              <option value="2000">2000+</option>
              <option value="5000">5000+</option>
              <option value="10000">10000+</option>
              <option value="50000">50000+</option>
              <option value="100000">100000+</option>
            </select>
          </div>

          <div class="form-group">
            <label class="">兴趣爱好</label>
            <textarea v-model="data.infoInterest" maxlength="50"></textarea>
          </div>

          <button class="save" @click="save">保存</button>
        </div>
        <!--forms-->
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>
<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import sidebar from "./sidebar.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,

      year: 2022,
      days: 31,
      tab: "",
      data: null,
      provinces: [],
      citys: [],
      districts: [],
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    sidebar,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/info") },
      });

    api.all([this.loadProvince(), this.loadData()]).then(
      api.spread(() => {
        this.loading = false;
        this.tab = "base";
        // this.data.infoProvince = 30;
        // this.data.infoCity = 323;
        // this.data.infoCounty = 2882;
        if (this.data.infoProvince) {
          this.changeCity({
            currentTarget: { dataset: { type: "province" } },
          }).then(() => {
            if (this.data.infoCity) {
              this.changeCity({
                currentTarget: { dataset: { type: "city" } },
              });
            }
          });
        }
      })
    );
  },

  methods: {
    loadData: function () {
      return api.post(apis.center.info, {}).then((res) => {
        console.log(res);
        const date = res.infoBirthday
          ? res.infoBirthday.split("-")
          : ["", "", ""];

        res.infoBirthday = {
          year: date[0],
          month: date[1],
          day: date[2],
        };

        this.data = res;
        this.changeMonth();

        this.year = new Date().getFullYear();
      });
    },

    loadProvince: function () {
      return api.post(apis.common.province, {}).then((res) => {
        this.provinces = res;
      });
    },

    changeCity: function (e) {
      const target = e.target;
      const type = e.currentTarget.dataset.type;
      if (!type) return;

      if (type == "province") {
        return api
          .post(apis.common.city, { provinceId: this.data.infoProvince })
          .then((res) => {
            if (target !== undefined) {
              this.data.infoCity = 0;
              this.data.infoCounty = 0;
            }
            this.citys = res;
            this.districts = [];
          });
      } else {
        return api
          .post(apis.common.district, { cityId: this.data.infoCity })
          .then((res) => {
            if (target !== undefined) {
              this.data.infoCounty = 0;
            }
            this.districts = res;
          });
      }
    },

    changeMonth() {
      let days = 31;
      let month30 = [4, 6, 9, 11];
      if (month30.indexOf(this.data.infoBirthday.month) !== -1) {
        days = 30;
      } else if (this.data.infoBirthday.month === "02") {
        if (
          this.data.infoBirthday.year &&
          this.data.infoBirthday.year % 4 === 0
        ) {
          days = 29;
        } else {
          days = 28;
        }
      }
      this.days = days;
    },

    switchTab: function (e) {
      this.tab = e.currentTarget.dataset.tab;
    },

    save: function () {
      var params = {
        infoId: this.data.infoId,
        customerId: this.data.customerId,
        infoAddress: this.data.infoAddress,
        infoInterest: this.data.infoInterest,
        customerNickname: this.data.customerNickname,
        infoGender: this.data.infoGender,
        infoRealname: this.data.infoRealname,
        infoCardid: this.data.infoCardid,
        infoProvince: this.data.infoProvince + "",
        infoCity: this.data.infoCity + "",
        infoCounty: this.data.infoCounty + "",
        infoBirthday:
          this.data.infoBirthday.year +
          "-" +
          this.data.infoBirthday.month +
          "-" +
          this.data.infoBirthday.day,
        infoSalary: this.data.infoSalary,
        infoMarital: this.data.infoMarital,
      };

      if (!params.customerNickname) {
        return store.dispatch("toast", "请输入昵称");
      }

      if (!params.infoRealname) {
        return store.dispatch("toast", "请输入真实姓名");
      }

      if (
        params.infoCardid &&
        !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(
          params.infoCardid
        )
      ) {
        return store.dispatch("toast", "身份证号码不正确");
      }

      if (params.infoProvince && (!params.infoCity || !params.infoCounty)) {
        return store.dispatch("toast", "请选择完整所在地");
      }

      return api.post(apis.center.editInfo, params).then((res) => {
        console.log(res);
        store.dispatch("toast", "保存成功");
      });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./info.less");
@import url("../../assets/less/form.less");

.form-group {
  padding: 10px 0px;

  input[type="text"],
  input[type="number"],
  textarea {
    width: 300px;
    padding: 0 12px;
  }
}
</style>
